import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AboutUs } from './pages/aboutus';
import { Privacy } from './pages/privacy';
import { ContactUs } from './pages/contactus';
import { AllWheelPage } from './pages/allwheelpage';
import { IGenericPageOptions, IMenuItem } from './models/interfaces';
import { Menu } from './components/menu';
import HomePage from './pages/homepage';
import { GenericPage } from './pages/genericpage';
import foodConfiguration from "./configurations/foods.json"
import spinReadyConfigurations from "./configurations/spinready.json"
import { NumberWheel } from './components/numberwheel';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [menu, setMenu] = useState<IMenuItem[]>([]);
  const { pathname } = useLocation();

  useEffect(() => {
    const menuItems: IMenuItem[] = [
      {
        url: "/",
        text: "",
        children: <img src="./carkcevirmelogo-web.png" alt="logo" />,
        className: "d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none",
        page: <HomePage />
      },
      {
        url: "/ne-yesem",
        text: "Ne Yesem?",
        page: <GenericPage wheelConfiguration={foodConfiguration.wheelConfiguration}
          descriptionConfiguration={foodConfiguration.descriptionConfiguration}
          helmetConfiguration={foodConfiguration.helmetConfiguration} />
      },
      {
        url: "/sayi-carki",
        text: "Sayı Çarkı",
        page: <NumberWheel />
      },
      {
        url: "/tum-carklar",
        text: "Tüm Çarklar",
        page: <AllWheelPage />
      },
      {
        url: "/hakkimizda",
        text: "Hakkımızda",
        page: <AboutUs />,
        hiddenMenu: true
      },
      {
        url: "/gizlilik",
        text: "Gizlilik",
        page: <Privacy />,
        hiddenMenu: true
      },
      {
        url: "/iletisim",
        text: "İletişim",
        page: <ContactUs />,
        hiddenMenu: true
      }
    ];

    for (let index = 0; index < spinReadyConfigurations.length; index++) {
      const spinReadyConfiguration: IGenericPageOptions = spinReadyConfigurations[index];
      menuItems.push({
        url: spinReadyConfiguration.url || "",
        text: "",
        hiddenMenu: true,
        page: <GenericPage wheelConfiguration={spinReadyConfiguration.wheelConfiguration}
          descriptionConfiguration={spinReadyConfiguration.descriptionConfiguration}
          helmetConfiguration={spinReadyConfiguration.helmetConfiguration}
        />
      });
    }

    setMenu(menuItems);

    const script = document.createElement('script');

    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6611253041673251";
    script.async = true;
    script.crossOrigin = "anonymous";

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, [])

  return (
    <div className="App">
      <div className="container py-4">
        <header className="d-flex flex-wrap w-100 pb-3 mb-4 border-bottom">
          <Menu items={menu} activeRoute={pathname}></Menu>
        </header>
        <Routes>
          {menu.map((menuitem: IMenuItem, index: number) => {
            return <Route key={index} path={menuitem.url} element={menuitem.page} />
          })}
        </Routes>
        <footer className="d-flex flex-wrap justify-content-between align-items-center border-top">
          <p className="col-md-4 mb-0 text-muted text-start">© 2024 carkcevirme.com</p>
          <ul className="nav col-md-4 justify-content-end">
            <li className="nav-item"><a href="/hakkimizda" className="nav-link px-2 text-muted">Hakkımızda</a></li>
            <li className="nav-item"><a href="/gizlilik" className="nav-link px-2 text-muted">Gizlilik</a></li>
            <li className="nav-item"><a href="/iletisim" className="nav-link px-2 text-muted">İletişim</a></li>
          </ul>
        </footer>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default App;
