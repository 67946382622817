import { Link } from "react-router-dom";
import { IMenuItemProps } from "../../models/interfaces";

export function MenuItem(props: IMenuItemProps) {
    const getClassName = () => {
        let className = "";
        if (props.isSelected) {
            className = "nav-link-selected ";
        }
        if (props.className) {
            className += props.className;
        }
        else {
            className += "nav-link px-2 link-dark";
        }
        return className;
    }
    return (
        <li className="nav-item">
            <Link to={props.url} className={getClassName()}>
                {props.text || props.children}
            </Link>
        </li>
    );
}