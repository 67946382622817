import { useEffect, useState } from "react";
import { Wheel } from "../../components/wheel";
import { IGenericPageOptions, IWheelProps } from "../../models/interfaces";
import { Description } from "../../components/description";
import React from "react";
import { CustomHelmet } from "../../components/customhelmet";

export function GenericPage(props: IGenericPageOptions) {
    const [wheelConfiguration, setWheelConfiguration] = useState<IWheelProps>();

    useEffect(() => {
        setWheelConfiguration(props.wheelConfiguration);
    }, [props.wheelConfiguration])
    return (<React.Fragment>
        <Wheel initialOptions={wheelConfiguration?.initialOptions}
            customColors={wheelConfiguration?.customColors}
            hideEditOption={wheelConfiguration?.hideEditOption}
            groups={wheelConfiguration?.groups} />
        {
            props.descriptionConfiguration &&
            <Description
                title={props.descriptionConfiguration.title}
                description={props.descriptionConfiguration.description}
            ></Description>
        }

        {
            props.helmetConfiguration &&
            <CustomHelmet
                title={props.helmetConfiguration.title}
                description={props.helmetConfiguration.description}
                keywords={props.helmetConfiguration.keywords}
                ogTitle={props.helmetConfiguration.ogTitle}
                ogDescription={props.helmetConfiguration.ogDescription}
                ogImage={props.helmetConfiguration.ogImage}
            ></CustomHelmet>
        }
    </React.Fragment>);
}