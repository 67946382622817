import React from "react";

export function AboutUs() {
    return ( 
    <React.Fragment>
        <h1 className="cc-h1">Hakkımızda</h1>
        <div style={{textAlign: "justify"}}>
        <p>carkcevirme.com, eğlence dolu bir karar çarkı deneyimi sunan bir çevrimiçi platformdur. Karar vermek zor olabilir ve bazen nereden başlayacağınızı bilemeyebilirsiniz. İşte bu noktada, size yardımcı olmak ve kararlarınızı daha eğlenceli hale getirmek için buradayız!</p>

        <p>Amacımız, her türlü kararı alırken size rehberlik etmek ve hayatınızı kolaylaştırmaktır. Kararlarınızı rastgele bir şekilde atmak yerine, interaktif bir karar çarkı kullanarak eğlenceli bir yöntemle kararlar almanızı sağlıyoruz. Her türlü konuda kullanabileceğiniz çeşitli karar çarkı seçenekleri sunuyoruz ve istediğiniz gibi özelleştirebilirsiniz. Hangi restorana gideceğinizi, hangi filmi izleyeceğinizi veya hangi seyahate çıkacağınızı belirlemek için carkcevirme.com sizin yanınızda!</p>

        <p>Bizler, kullanıcı deneyimine ve güvenliğe büyük önem veriyoruz. Sitemizdeki karar çarklarını kullanırken kişisel bilgilerinizi talep etmiyoruz ve gizlilik politikalarına sıkı sıkıya bağlıyız. Kullanıcılarımızın güvenliğini sağlamak için çeşitli güvenlik önlemleri almaktayız ve platformumuzu en son teknolojik gelişmelere göre güncel tutmaktayız.</p>

        <p>carkcevirme.com olarak, kullanıcılarımızın ihtiyaçlarına odaklanmayı ve onlara en iyi deneyimi sunmayı hedefliyoruz. Sitemizde sürekli olarak yeni karar çarkları ekliyoruz ve geri bildirimlerinizi dikkate alarak iyileştirmeler yapıyoruz. Kullanıcılarımızın memnuniyeti ve eğlencesi bizim için en önemli önceliktir.</p>

        <p>Eğer karar vermek için biraz heyecan arıyorsanız veya sıradan kararlarınıza biraz renk katmak isterseniz, carkcevirme.com tam size göre bir yerdir. Sitemizi keşfedin, bir karar çarkını çevirin ve eğlenceli bir karar alma deneyiminin keyfini çıkarın!</p>

        <p>Bize katıldığınız için teşekkür ederiz!</p>

<strong>carkcevirme.com Ekibi</strong><br/><i>OguzKK, relre</i>
        </div>
    </React.Fragment>);
}