import "./style.scss";
import React from "react";
import { ISwitchProps } from "../../models/interfaces";

export function Switch(props: ISwitchProps) {
    return (<React.Fragment>
        <div className="switch">
            <div>
                {props.label}
            </div>
            <label className="switch-label">
                <input type="checkbox" checked={props.checked} onChange={() => props.onCheckedChange()} disabled={props.disabled}>
                </input>
                <span className="slider round"></span>
            </label>
        </div>
    </React.Fragment >);
}