import React from "react";

export function AllWheelPage() {
    return (
        <React.Fragment>
            <h1 className="cc-h1">Tüm Çarklar</h1>
            <div className="row">
                <div className="col-sm-6 mx-auto">
                    <div className="text-start mt-3">
                        <div className="group-header">Genel Kategori</div>
                        <ul className="list-group">
                            <li className="list-group-item"><a href="/sayi-carki" className="link-cc">Sayı Çarkı</a></li>
                            <li className="list-group-item"><a href="/evet-hayir" className="link-cc">Evet mi Hayır mı?</a></li>
                            <li className="list-group-item"><a href="/dogruluk-cesaretlik-carki" className="link-cc">Doğruluk mu Cesaretlik mi?</a></li>
                            <li className="list-group-item"><a href="/para-carki" className="link-cc">Para Çarkı</a></li>
                            <li className="list-group-item"><a href="/alfabe-carki" className="link-cc">Alfabe Çarkı</a></li>
                            <li className="list-group-item">Ülkeler Çarkı <span className="badge bg-secondary">Yakında</span></li>
                            <li className="list-group-item">Türkiye Şehirleri Çarkı <span className="badge bg-secondary">Yakında</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-sm-6 mx-auto">
                    <div className="text-start mt-3">
                        <div className="group-header">Yemek</div>
                        <ul className="list-group">
                            <li className="list-group-item"><a href="/ne-yesem" className="link-cc">Ne yesem?</a></li>
                            <li className="list-group-item">Hangi Starbucks Kahvesi? <span className="badge bg-secondary">Yakında</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-sm-6 mx-auto">
                    <div className="text-start mt-3">
                        <div className="group-header">Oyun</div>
                        <ul className="list-group">
                            <li className="list-group-item"><a href="/rastgele-lol-karakteri" className="link-cc">Rastgele LoL Karakteri</a></li>
                            <li className="list-group-item"><a href="/rastgele-valorant-ajani" className="link-cc">Rastgele Valorant Ajanı</a></li>
                            <li className="list-group-item">Hangi Dota Karakteri? <span className="badge bg-secondary">Yakında</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>);
}