import React from "react";

export function Privacy() {
    return (
    <React.Fragment>
    <h1 className="cc-h1">Gizlilik ve Çerez Politikası</h1>
    <div style={{textAlign: "justify"}}>
    <p>Gizlilik ve kullanıcı güvenliği, carkcevirme.com olarak en önemli önceliklerimizdendir. Bu gizlilik politikası, carkcevirme.com web sitesinin kullanımıyla ilgili bilgilerin nasıl toplandığını, kullanıldığını ve korunduğunu açıklamaktadır.</p>

    <h2>1. Bilgi Toplama ve Kullanımı:</h2>
    <p>carkcevirme.com web sitesini ziyaret ettiğinizde, bazı kişisel olmayan bilgileri otomatik olarak toplarız. Bu bilgiler, tarayıcınızın türü, işletim sistemi, internet protokol (IP) adresi, cihazınızın modeli ve sürümü gibi genel demografik bilgileri içerebilir. Bu bilgiler, web sitemizin performansını analiz etmek, kullanıcı deneyimini iyileştirmek ve güvenliğini sağlamak amacıyla kullanılır.</p>

    <h2>2. Çerezler:</h2>
    <p>carkcevirme.com, web sitesinin verimli çalışmasını sağlamak ve kullanıcı deneyimini iyileştirmek için çerezleri kullanır. Çerezler, kullanıcının tercihlerini hatırlayarak tekrar ziyaretlerde daha kişiselleştirilmiş bir deneyim sunar. Ayrıca, trafik analizi yapmak, web sitesi kullanımını izlemek ve pazarlama faaliyetlerini desteklemek için çerezleri kullanabiliriz.</p>

    <h2>3. Üçüncü Taraf Bağlantıları:</h2>
    <p>carkcevirme.com, kullanıcıların daha fazla bilgi edinmelerini sağlamak amacıyla üçüncü taraf web sitelerine bağlantılar içerebilir. Bu web sitelerinin gizlilik uygulamalarıyla ilgili olarak herhangi bir sorumluluk taşımamaktayız. Bağlantıları kullanırken dikkatli olmanızı ve ilgili web sitesinin gizlilik politikasını incelemenizi öneririz.</p>

    <h2>4. Kişisel Bilgilerin Korunması:</h2>
    <p>carkcevirme.com, topladığı kişisel bilgileri satmaz, kiralamaz veya başka bir şekilde üçüncü taraflarla paylaşmaz. Kişisel bilgilerinizi yalnızca belirli bir amaca hizmet etmek için kullanırız ve güvenliğini sağlamak için gerekli önlemleri alırız.</p>

    <h2>5. Değişiklikler:</h2>
    <p>Gizlilik politikamızı zaman zaman güncelleyebiliriz. Bu değişiklikler web sitemizde yayınlanacak ve güncel politikayı yansıtacaktır. Gizlilik politikasını düzenli olarak kontrol etmeniz önerilir.</p>

    <p>carkcevirme.com'u ziyaret ederek, bu gizlilik politikasını kabul etmiş olursunuz. Gizlilikle ilgili herhangi bir sorunuz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.</p>

    <p>Son güncelleme tarihi: [04.06.2023]</p>
    </div>
    </React.Fragment>
    );
}