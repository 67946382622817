import { IDescriptionProps } from "../../models/interfaces";
export function Description(props: IDescriptionProps) {
    return (
        <div className="row text-start mt-5 mb-5">
            {props.title &&
                <div>
                    <h1 className="cc-h1">
                        {props.title}
                    </h1>
                </div>
            }
            {props.description &&
                <div className="cc-desc" dangerouslySetInnerHTML={{ __html: props.description }}>
                </div>
            }
        </div>
    )
}