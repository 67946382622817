import "./style.scss";
import React from "react";
import { IMenuItem, IMenuProps } from "../../models/interfaces";
import { MenuItem } from "../menuitem";

export function Menu(props: IMenuProps) {
    return (<React.Fragment>
        <ul className="menu nav nav-pills">
            {props.items.map((menuitem: IMenuItem, index: number) => {
                return !menuitem.hiddenMenu && <MenuItem key={index}
                    url={menuitem.url}
                    text={menuitem.text}
                    className={menuitem.className}
                    children={menuitem.children}
                    isSelected={props.activeRoute === menuitem.url}></MenuItem>

            })}
        </ul>
    </React.Fragment>);
}