import React from "react";
import { Wheel } from "../../components/wheel";
import { Description } from "../../components/description";
import { CustomHelmet } from "../../components/customhelmet";


export default function HomePage() {
    return <React.Fragment><Wheel></Wheel>
        <Description title="Çark Çevirme - Karar Çarkı" description="Çark Çevirme, kararsızlık yaşayanlara eğlenceli bir çözüm sunan interaktif bir platformdur. Kendi karar çarkınızı oluşturarak zorlu kararlarınızı şansa bırakabilir ve seçim yapmakta güçlük çektiğiniz durumlarda size yol gösterebilir. Karar Çarkı, eğlenceli ve pratik bir yöntemle karar verme sürecinizi kolaylaştırır."></Description>
        <CustomHelmet title="Çark Çevirme - Karar Çarkı!"
            description="Çark çevir. Arada derede kalma, çarkı çevir ve karar ver! Çarkı çevirmek için 2 seçenek gir ve kazananı belirle. Doğruluk mu cesaretlik mi?"
            keywords="cark cevir, çark çevir, çark, çevirme, karar çarkı, çark oyunu, karar ver, ne yesem, yemeğe karar ver, ne yiyeceğim, ne yiyeceğine karar ver"
            ogTitle="Çark Çevir - Karar Çarkı"
            ogDescription="Çark çevir. Arada derede kalma, çarkı çevir ve karar ver!"
            ogImage="https://carkcevirme.com/carkcevirmelogo-og-image.png"
        />
        <div className="container">
            <div className="row text-start mt-5">
                <div className="row">
                    <div className="mt-5 text-center">
                        <h2 className="cc-h1">
                            Kendi Çarkınızı Oluşturun
                        </h2>
                    </div>
                    <div className="cc-desc text-center">
                        Kendi özelleştirilmiş çarkınızı oluşturarak herhangi bir konuda karar verirken şansa yer verebilir ve heyecan verici bir deneyim yaşayabilirsiniz.
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src="../cark-adim1.png" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>1. Seçenekleri Girin</h5>
                            <p>Çarkın sol tarafında ki kısımdan çarka eklemek istediğiniz seçenekleri girin. Girmiş olduğunuz seçeneği çarka eklemek için "Ekle" butonuna basın veya klavyenizden "Enter" tuşunu kullanın.</p>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>2. Toplu Seçenek Girme Özelliğini Kullanın</h5>
                            <p>Eğer birçok seçeneğiniz varsa, toplu seçenek girme özelliğini kullanabilirsiniz. Bu özellik genellikle birden çok satırı kopyalayıp yapıştırmanıza olanak tanır. Seçeneklerinizi girmek için bu özelliği kullanarak hızlı ve verimli bir şekilde kullanabilirsiniz.</p>
                        </div>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src="../cark-adim2.png" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src="../cark-adim3.png" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>3. Seçenekleri Gözden Geçirin</h5>
                            <p>Çarkın sol tarafında ki kısımdan çarka eklemek istediğiniz seçenekleri girin. Girmiş olduğunuz seçeneği çarka eklemek için "Ekle" düğmesine tıklayın veya klavyenizden "Enter" tuşunu kullanın.</p>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>4. Çarkı Çevir!</h5>
                            <p>Seçenekleri girdikten veya gözden geçirdikten sonra çarkı çevirme zamanı! Çarkı çevirmek için çarkın ortasında bulunan düğmeye tıklayarak çarkı döndürün. Sistem, belirtilen seçeneklerin rastgele bir şekilde seçilmesini sağlayacak ve sonucu gösterecektir. </p>
                        </div>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src="../cark-adim4.png" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src="../cark-adim5.png" className="img-fluid"></img>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                        <div className="d-block">
                            <h5>5. Seçenekleri Silebilirsiniz</h5>
                            <p>Sileceğiniz seçenekleri çarktan kaldırmak için sol tarafta bulunan "Çöp Kutusu" ikonuna tıklayın. Bu adımı kullanarak istediğiniz zaman istediğiniz seçeneği silebilirsiniz.</p>
                        </div>
                    </div>
                </div>

                <div className="row text-start mt-5">
                    <div className="mt-5 text-center">
                        <h2 className="cc-h1">
                            Popüler Hazır Çarklar
                        </h2>
                    </div>

                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-3">
                            <a href="/ne-yesem"><img src="../images/ne-yesem.jpg" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href="/ne-yesem" className="cc-link">Ne Yesem? Çarkı 🍴</a></h5>
                                <p>Hangi yemeği seçeceğinizi düşünmekten sıkıldınız mı?</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-3">
                            <a href="/evet-hayir"><img src="../images/evet-hayir.jpg" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href="/evet-hayir" className="cc-link">Evet ✅ Hayır ❌ Çarkı  </a></h5>
                                <p>Kararını ver! Evet mi Hayır mı?</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-5">
                            <a href="/dogruluk-cesaretlik-carki"><img src="../images/dogruluk-cesaretlik-carki.jpg" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href="/dogruluk-cesaretlik-carki" className="cc-link">Doğruluk mu 😳 Cesaretlik mi? 😈 Çarkı</a></h5>
                                <p>Bir şişeye ihtiyacın yok. Çarkın hazır ve burada :)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 d-flex">
                        <div className="col-sm-6 text-center mt-5">
                            <a href="/rastgele-lol-karakteri"><img src="../images/rastgele-lol-karakteri.jpg" className="img-fluid-custom"></img></a>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center mt-3">
                            <div className="d-block">
                                <h5><a href="/rastgele-lol-karakteri" className="cc-link">Rastgele LoL Karakteri 🎮</a></h5>
                                <p>Hangi şampiyon ile oynayacağını mı düşünüyorsun?</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 text-center mt-5 mb-5">
                        <a href="/tum-carklar" className="btn btn-primary">Tüm Çarkları Görüntüle</a>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}