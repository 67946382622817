import "./style.scss";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IWheelGroupConfiguration, IWheelProps, WheelOption } from '../../models/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import confetti from "canvas-confetti";
import { rgbToHex } from "../../helpers/generalhelper";
import { Switch } from "../switch";
import { toast } from 'react-toastify';


export function Wheel(props: IWheelProps) {
    const getInitialOptions = (): WheelOption[] => {
        const initialOptions: WheelOption[] = [];
        const textOptions = ["Seçenek 1", "Seçenek 2", "Seçenek 3", "Seçenek 4", "Seçenek 5", "Seçenek 6"];
        textOptions.forEach((text: string, index: number) => {
            initialOptions.push({ text: text, id: index, visible: true });
        });
        return initialOptions;
    }

    const [canvasSize, setCanvasSize] = useState<number>(500);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const winnerDivRef = useRef<HTMLDivElement>(null);
    const winnerLabelRef = useRef<HTMLLabelElement>(null);
    const colors = useRef<string[]>([]);
    const defaultColors = useRef<string[]>([]);
    const currentAngle = useRef<number>(0);
    const winnerOption = useRef<WheelOption>();
    const [options, setOptions] = useState<WheelOption[]>(getInitialOptions());
    const [textValue, setTextValue] = useState<string>("");
    const [winner, setWinner] = useState<WheelOption>();
    const [isWheelSpining, setIsWheelSpining] = useState<boolean>(false);
    const [hasDefaultOptions, setHasDefaultOptions] = useState<boolean>(true);
    const [hasInitialOptions, setHasInitialOptions] = useState<boolean>(true);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [multiEnabled, setMultiEnabled] = useState<boolean>(false);
    const [multiValue, setMultiValue] = useState<string>("");
    const [hideEditOption, sethideEditOption] = useState<boolean>(false);
    const [groups, setGroups] = useState<IWheelGroupConfiguration[]>([]);



    const getRotationDegrees = (element: HTMLElement) => {
        const style = window.getComputedStyle(element);
        const transformString = style.transform;
        if (!transformString || transformString === 'none')
            return 0;
        const splits = transformString.split(',');
        const parenLoc = splits[0].indexOf('(');
        const a = parseFloat(splits[0].substr(parenLoc + 1));
        const b = parseFloat(splits[1]);
        const rad = Math.atan2(b, a);
        let deg = 180 * rad / Math.PI;
        if (deg < 0) deg += 360;
        return deg;
    }

    useEffect(() => {
        if (window.screen.width < 520) {
            setCanvasSize(window.screen.width - 20);
        }
        if (defaultColors.current.length === 0) {
            defaultColors.current.push("#D50156");
            defaultColors.current.push("#ED4F24");
            defaultColors.current.push("#FDC702");
            defaultColors.current.push("#62C52F");
            defaultColors.current.push("#1581DA");
            defaultColors.current.push("#5D0AF9");
        }
        if (colors.current.length === 0) {
            for (let r = 0; r < 100; r++) colors.current.push(rgbToHex(r * 255 / 100, 255, 0));
            for (let g = 100; g > 0; g--) colors.current.push(rgbToHex(255, g * 255 / 100, 0));
            for (let b = 0; b < 100; b++) colors.current.push(rgbToHex(255, 0, b * 255 / 100));
            for (let r = 100; r > 0; r--) colors.current.push(rgbToHex(r * 255 / 100, 0, 255));
            for (let g = 0; g < 100; g++) colors.current.push(rgbToHex(0, g * 255 / 100, 255));
            for (let b = 100; b > 0; b--) colors.current.push(rgbToHex(0, 255, b * 255 / 100));
            colors.current.push(rgbToHex(0, 255, 0));
        }
    }, [])

    useEffect(() => {
        if (props.initialOptions) {
            let newOptions: WheelOption[] = [...props.initialOptions];
            if (winnerDivRef.current) {
                winnerDivRef.current.style.backgroundColor = "#fff";
            }
            setHasInitialOptions(true);
            setHasDefaultOptions(false);
            newOptions.forEach((item: WheelOption, index: number) => {
                item.id = index;
                item.visible = true;
            });
            setOptions([...newOptions]);
        }
    }, [props.initialOptions])

    useEffect(() => {
        const ctx = canvasRef.current && canvasRef.current.getContext("2d");
        const visibleOptions = options.filter(option => { return option.visible; });
        if (ctx) {
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            const portionAngle = (1 / visibleOptions.length) * 2 * Math.PI;
            let currentAngle = 0;
            for (let index = 0; index < visibleOptions.length; index++) {
                const option = visibleOptions[index];
                ctx.beginPath();
                ctx.lineTo(canvasSize / 2, canvasSize / 2);
                ctx.arc(canvasSize / 2, canvasSize / 2, (canvasSize / 2) - 20, currentAngle, currentAngle + portionAngle);
                currentAngle += portionAngle;
                ctx.lineTo(canvasSize / 2, canvasSize / 2);
                ctx.lineWidth = 2;
                ctx.strokeStyle = 'white';


                ctx.stroke();
                let color = "";
                if (props.customColors && props.customColors.length > 0) {
                    let customColorIndex = index;
                    if (index > props.customColors.length - 1) {
                        customColorIndex = index % props.customColors.length;
                    }
                    color = props.customColors[customColorIndex];
                }
                else if (visibleOptions.length <= defaultColors.current.length) {
                    color = defaultColors.current[index];
                }
                else {
                    color = colors.current[Math.floor((index / visibleOptions.length * colors.current.length))];
                }
                ctx.fillStyle = color;
                option.color = color;
                ctx.fill();
                let text = option.text;
                let font = Math.min(canvasSize / (visibleOptions.length + 10), canvasSize / 17);
                ctx.font = '600 ' + font + 'px Arial';
                ctx.save();
                ctx.translate(canvasSize / 2, canvasSize / 2);
                ctx.rotate(currentAngle - (portionAngle / 2));
                ctx.textAlign = 'left';
                ctx.strokeStyle = 'black';
                let textWidth = ctx.measureText(text).width;
                const maxWidth = (canvasSize * 3 / 8) - 20;
                if (textWidth > maxWidth) {
                    while (textWidth > maxWidth - 20) {
                        text = text.slice(0, text.length - 2);
                        textWidth = ctx.measureText(text).width;
                    }
                    text = text + "...";
                }

                ctx.fillStyle = 'white';
                ctx.fillText(text, canvasSize / 8, 5);
                ctx.restore();

                ctx.beginPath();
                ctx.arc(canvasSize / 2, canvasSize / 2, canvasSize / 15, 0, 2 * Math.PI, false);
                ctx.fillStyle = 'white';
                ctx.fill();
                ctx.lineWidth = 5;
                ctx.strokeStyle = 'white';
                ctx.stroke();
            }
        }
    }, [options, canvasSize, props.customColors]);

    useEffect(() => {
        sethideEditOption(props.hideEditOption || false);
    }, [props.hideEditOption])

    useEffect(() => {
        const groupConfigruations: IWheelGroupConfiguration[] = [];
        if (props.groups) {
            for (let index = 0; index < props.groups.length; index++) {
                const element = props.groups[index];
                groupConfigruations.push({
                    groupName: element,
                    isSelected: true
                });
            }
        }
        setGroups(groupConfigruations || []);
    }, [props.groups])

    const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setTextValue(text);
    }

    const onMultiValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value;
        setMultiValue(text);
        let newOptions: WheelOption[] = [];
        const textOptions = text.split("\n");
        textOptions.forEach((option, index) => {
            newOptions.push({ text: option, id: index, visible: true });
        });
        setOptions([...newOptions]);
    }


    const spinWheel = () => {
        canvasRef.current!.className = "wheel-canvas";
        const visibleOptions = options.filter(option => { return option.visible; });
        setTimeout(() => {
            setWinner(undefined);
            setIsWheelSpining(true);
            const x = 1024; //min value
            const y = 2048; // max value

            currentAngle.current += Math.floor(Math.random() * (x - y)) + y + y;

            if (canvasRef.current) {
                canvasRef.current.style.transform = "rotate(" + currentAngle.current + "deg)";
            }
            const portionAngle = (1 / visibleOptions.length) * 360;

            const interval = setInterval(() => {
                let deg = getRotationDegrees(canvasRef.current!);
                const portionAngle = (1 / visibleOptions.length) * 360;
                if (deg < 225) {
                    deg = deg + 360;
                }
                const index = visibleOptions.length - 1 - Math.floor(((deg - 225) % 360) / portionAngle);
                const newWinner = visibleOptions[index];
                changeWinner(newWinner);
            }, 50);

            const timeout = setTimeout(() => {
                setIsWheelSpining(false);
                clearInterval(interval);
                const foundWinner = visibleOptions[visibleOptions.length - 1 - Math.floor(((currentAngle.current - 225) % 360) / portionAngle)];
                changeWinner(foundWinner);
                setWinner(foundWinner);
                if (winnerLabelRef.current) {
                    winnerLabelRef.current.innerHTML = "KAZANAN: " + foundWinner.text + "</div>";
                }
                setModalVisible(true);
                const confettiOptions: confetti.Options = { particleCount: 100, origin: { x: 0.5, y: 0.6 } };
                confetti(confettiOptions);
                const popupTimeout = setTimeout(() => {
                    closeModal();
                    clearTimeout(popupTimeout);
                }, 5000);
                clearTimeout(timeout);
            }, 5000);
        });

    }

    const changeWinner = (option: WheelOption) => {
        if (winnerDivRef.current && option.color && winnerLabelRef.current &&
            (!winnerOption.current || option.id !== winnerOption.current.id)) {
            winnerDivRef.current.style.backgroundColor = option.color;
            winnerLabelRef.current.innerHTML = option.text;
        }
    }

    const onAddClick = () => {
        if (textValue) {
            let newOptions: WheelOption[] = [...options];
            if (hasDefaultOptions) {
                setWinner(undefined);
                if (winnerDivRef.current) {
                    winnerDivRef.current.style.backgroundColor = "#fff";
                }
                newOptions = [];
                setHasDefaultOptions(false);
            }
            newOptions.push({ text: textValue, id: options.length, visible: true });
            newOptions.forEach((item: WheelOption, index: number) => {
                item.id = index;
            });
            setTextValue("");
            setOptions([...newOptions]);
        }
    }

    const onRemoveClick = (index: number) => {
        let newOptions = [...options];
        newOptions.splice(index, 1);
        if (newOptions.length === 0) {
            setHasDefaultOptions(true);
            newOptions = getInitialOptions();
        }
        setOptions([...newOptions]);
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            onAddClick();
        }
    }

    const closeModal = () => {
        setModalVisible(false);
    }

    const onCheckedChange = () => {
        if (!multiEnabled) {
            let value = "";
            options.forEach(((option, index) => {
                value += option.text;
                if (index !== options.length - 1) {
                    value += "\n";
                }
            }));
            value.padEnd(3);
            setMultiValue(value);
        }
        setMultiEnabled(!multiEnabled);
    }

    return (
        <div className={"wheel"}>
            <div className="row">
                <div className="col-sm-6">
                    <div className="wheel-table">
                        <div className="wheel-table-intro m-3 p-3 text-center" ref={winnerDivRef}>
                            {(isWheelSpining || winner) ? <label className="winner-label" ref={winnerLabelRef}></label> : <div className="wheel-table-intro-initial">Çevire <FontAwesomeIcon icon={faCirclePlay} /> bas ve çarkı çevir!</div>}
                        </div>
                        <div className="wheel-table-count m-3 p-3 text-center">
                            Seçenek sayısı: {options.filter(option => { return option.visible; }).length}
                        </div>
                        {groups && groups.map((group, index) => {
                            return <Switch key={index} label={group.groupName} disabled={isWheelSpining} checked={group.isSelected} onCheckedChange={() => {
                                if (group.isSelected === true && groups.filter(group => group.isSelected === true).length === 1) {
                                    toast.error("En az bir seçenek açık kalmalıdır.");
                                    return;
                                }
                                group.isSelected = !group.isSelected;
                                options.forEach((option) => {
                                    if (option.group === group.groupName) {
                                        option.visible = group.isSelected;
                                    }
                                });
                                setOptions([...options]);
                                setGroups([...groups]);
                            }}></Switch>
                        })}
                        {!hideEditOption && <div className="wheel-input">
                            <Switch label="Toplu Seçenek Gir" checked={multiEnabled} disabled={isWheelSpining} onCheckedChange={onCheckedChange}></Switch>
                        </div>}
                        {multiEnabled && <div className="wheel-input m-3 p-3">
                            <textarea className="form-control" value={multiValue} onChange={onMultiValueChange}></textarea>
                        </div>}
                        {!multiEnabled && !hideEditOption && <div className="wheel-input input-group m-3 p-3">
                            <input type="text" className="form-control" placeholder="Çarka seçenek ekleyin" aria-describedby="button-addon2"
                                maxLength={30}
                                disabled={isWheelSpining}
                                value={textValue}
                                onChange={onTextChange}
                                onKeyDown={onKeyDown} />
                            <button className="btn btn-outline-secondary"
                                disabled={isWheelSpining}
                                type="button"
                                id="button-addon2"
                                onClick={onAddClick}>Ekle</button>
                        </div>}
                        {!multiEnabled && <div className="wheel-table-entry-box m-3 p-3">
                            <ul className="list-group">
                                {options.filter(option => { return option.visible; }).map((option, index) => {
                                    return option.visible &&
                                        <li key={index} className={index % 2 === 0 ? "entry-item-dark" : "entry-item"}>
                                            <span>{option.text || " "}</span>
                                            {
                                                !hideEditOption &&
                                                <FontAwesomeIcon icon={faTrashCan} className="remove-entry" onClick={() => { onRemoveClick(index) }} />
                                            }
                                        </li>
                                })}
                            </ul>
                        </div>}
                    </div>
                </div>
                <div className="col-sm-6">
                    {
                        modalVisible && <div className="modalx">
                            <div className="modal-contentx">
                                <span className="close" onClick={closeModal}>&times;</span>
                                <div className="wheel-table-intro m-3 p-3 text-center">
                                    <label className="winner-label-modal">KAZANAN: {winner?.text}</label>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="wheel-canvas-wrapper" style={{ width: canvasSize, height: canvasSize }}>
                        <canvas className={"wheel-canvas " + (hasDefaultOptions || hasInitialOptions ? "wheel-canvas-initial" : "")} width={canvasSize} height={canvasSize} ref={canvasRef}></canvas>
                        <div className="bg-primary"><button className="playButton" onClick={spinWheel} disabled={isWheelSpining}><FontAwesomeIcon icon={faCirclePlay} /></button></div>
                    </div>
                </div>
            </div>
        </div >
    );
}
